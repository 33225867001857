import React from 'react';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Social from '../components/Social';
import '../assets/sass/_masthead.scss';
import ContactText from '../components/otherlanguage/ContactText';
import PrivacyPolicyHeader from '../components/header/Privacy_policyHeader';
import PrivacyAccordionTitle1 from '../components/otherlanguage/privacypolicy/PrivacyAccordionTitle1';
import PrivacyAccordionContant1 from '../components/otherlanguage/privacypolicy/PrivacyAccordionContant1';
import PrivacyPolicyText from '../components/otherlanguage/PrivacypolicyText';
import Accordion from 'react-bootstrap/Accordion';
import '../assets/sass/_accordion-overwrite.scss';

const privacy_policy = () => (
  <Layout>
    <PrivacyPolicyHeader />

    <header className="masthead">
      <div className="container h-100">
        <div className="row h-100">
          <div className="my-auto">
            <div className="header-content mx-auto">
              <section className="privacy" id="privacy">
              <div className="pagetitle">
                <PrivacyPolicyText/>
              </div>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <PrivacyAccordionTitle1/>
                  </Accordion.Header>
                    <Accordion.Body>
                      <PrivacyAccordionContant1/>
                    </Accordion.Body>
                  </Accordion.Item>
              </Accordion>
              </section>
            </div>
          </div>
        </div>
      </div>
    </header>

    <section className="contact bg-primary" id="contact">
      <div className="container">
        <h2>
          <ContactText/>
        </h2>
        <Social />
      </div>
    </section>
    <Footer />
  </Layout>
);

export default privacy_policy;
